import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Projects/davidMelloDotCom/node_modules/gatsby-theme-chronoblog/src/components/page/index.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const FeedItems = makeShortcode("FeedItems");
const Embed = makeShortcode("Embed");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "software-testing-and-quality-assurance"
    }}>{`Software Testing and Quality Assurance`}</h1>
    <p>{`I enjoy breaking software. I also enjoy writing software that breaks software. However, my favorite thing is teaching others how to become better software test engineers.`}</p>
    <p>{`I've compiled a list of tutorials on automated software testing tools and quality assurance techniques below. Check back often for new test automation tutorials and please reach out on Twitter if you have questions or have topic requests.`}</p>
    <p>{`You'll notice a lot of content on `}<a parentName="p" {...{
        "href": "https://nightwatchjs.org/"
      }}>{`Nightwatch.js`}</a>{` which is my preferred browser automation framework. It's written in Node.js on top of Selenium, but has functionality that makes your tests more reliable out of the box.`}</p>
    <h2 {...{
      "id": "software-testing-articles-and-tutorials"
    }}>{`Software Testing Articles and Tutorials`}</h2>
    <FeedItems filterByTags={['nightwatchjs', 'software testing', 'quality assurance']} mdxType="FeedItems" />
    <h2 {...{
      "id": "selenium-in-java"
    }}>{`Selenium in Java`}</h2>
    <p>{`Selenium Webdriver is a workhorse. Java is not my favorite language, but sometimes it is easier on your team to understand and help with testing if you write your tests in the same language the software being tested in written in.`}</p>
    <p>{`The tutorial below will show you how to write reliable and refactorable tests in Selenium by using dynamic waits and the page object model.`}</p>
    <Embed src="https://www.youtube.com/embed/N86y-XjHcqU" mdxType="Embed" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      